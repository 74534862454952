import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import CookieJs from "js-cookie";
import { get } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Breadcrumb from "../components/Breadcrumb";
import MainLayout from "../components/MainLayout";
import useNotification from "../hooks/useNotification";
import userService from "../services/users";
import event from "../services/event";
import queryDefault from "../utils/queryDefault";
import role from "../services/role";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";

export default function UserCreate() {
  const notification = useNotification();
  const navigate = useNavigate();
  const user = JSON.parse(CookieJs.get("USER") || "");
  const schema = yup.object().shape({
    name: yup
      .string()
      .min(5)
      .required("name is a required field and length > 5"),
    event_id: yup.string().required("event is a required field"),
    role_id: yup.string().required("role is a required field"),
    username: yup
      .string()
      .min(5)
      .matches(/^[a-z0-9]+$/, "lowercase, no symbol and space")
      .required("username is a required field and length > 5"),
  });
  const form = useForm({
    defaultValues: {
      name: "",
      username: "",
      phone_number: "",
      event_id: "",
      role_id: "",
      admin_name: user.username,
    },
    resolver: yupResolver(schema),
  });

  const createUser = useMutation("create_user", userService.createUser, {
    onSuccess: () => {
      navigate("/app/users/");

      notification.onOpen({
        message: "User was successfully created!",
        type: "success",
        position: "top",
      });
    },
    onError: () => {
      notification.onOpen({
        message: "Something went wrong",
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const onSubmit = (values: any) => {
    createUser.mutate({
      data: values,
    });
  };

  const listEvent = useQuery("list-event", () =>
    event.getAllEvent({ keyword: "" })
  );

  const listRole = useQuery("list-role", () => role.getAllRole());

  return (
    <MainLayout>
      <Breadcrumb
        label="Create User"
        breadcrumbs={[{ label: "Users", href: "/app/users" }]}
      />

      <ProtectedPermissionPage acceptPermissions={[99]}>
        <Box sx={{ mt: 2 }}>
          <Grid container>
            <Grid item lg={4} xs={12}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel required>Username</FormLabel>
                <Controller
                  name="username"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel required>Name</FormLabel>
                <Controller
                  name="name"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel required>Phone Number</FormLabel>
                <Controller
                  name="phone_number"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel required>Event</FormLabel>
                <Controller
                  name="event_id"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <Box>
                      <Select
                        {...field}
                        size="small"
                        error={Boolean(fieldState.error?.message)}
                        fullWidth
                      >
                        {get(listEvent, ["data", "data"], []).map(
                          (item: any, i: any) => (
                            <MenuItem value={item?.id} key={item?.id}>
                              {item?.name}
                            </MenuItem>
                          )
                        )}
                      </Select>

                      <FormHelperText error={Boolean(fieldState.error?.message)}>
                        {fieldState.error?.message}
                      </FormHelperText>
                    </Box>
                  )}
                />
              </FormControl>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel required>Role</FormLabel>
                <Controller
                  name="role_id"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <Box>
                      <Select
                        {...field}
                        size="small"
                        error={Boolean(fieldState.error?.message)}
                        fullWidth
                      >
                        {get(listRole, ["data", "data"], []).map(
                          (item: any, i: any) => (
                            <MenuItem value={item?.id} key={item?.id}>
                              {item?.name}
                            </MenuItem>
                          )
                        )}
                      </Select>

                      <FormHelperText error={Boolean(fieldState.error?.message)}>
                        {fieldState.error?.message}
                      </FormHelperText>
                    </Box>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Stack direction="row" spacing={1}>
            <LoadingButton
              variant="contained"
              onClick={form.handleSubmit(onSubmit)}
              loading={createUser.isLoading}
            >
              SAVE
            </LoadingButton>

            <Button variant="outlined" onClick={() => navigate(-1)}>
              CANCEL
            </Button>
          </Stack>
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
