import { Close, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import CookieJs from 'js-cookie';
import get from "lodash/get";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import MainLayout from "../components/MainLayout";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";
import useAlert from "../hooks/useAlert";
import useNotification from "../hooks/useNotification";
import event from "../services/event";
import config from "../utils/config";
import queryDefault from "../utils/queryDefault";

const CustomNoRowOverlay = () => (
  <Box sx={{ pt: 5 }}>
    <Typography variant="body1" textAlign="center" fontWeight="600">
      Sorry, data is not found
    </Typography>
  </Box>
);

export default function Event() {
  const user  = JSON.parse(CookieJs.get("USER") || "");
  const [pageSize, setPageSize] = useState<number>(10);
  const navigate = useNavigate();
  const notification = useNotification();
  const alert = useAlert();
  const [options, setOptions] = useState<any>({
    keyword: "",
  });
  const handleOnCellClick = (params: GridCellParams) => {
    const { row } = params;
    navigate(`/app/event/${row.id}`, { state: row });
  };
  const listEvent = useQuery(
    ["events", options],
    ({ queryKey }) => {
      return event.getAllEvent({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );
  useEffect(() => {
    if(user) {
      if(!user.is_admin) {
        window.location.href = `/app/event/${user.event_id}`
      }
    }
  }, [user])
  
  const deleteEvent = useMutation("delete-event", event.deleteEvent, {
    onSuccess: () => {
      listEvent.refetch();
      notification.onOpen({
        message: "Event was successfully deleted!",
        type: "success",
        position: "top",
      });
    },
    ...queryDefault,
  });
  const form = useForm({
    defaultValues: {
      keyword: "",
    },
  });
  const onDelete = (id: number) => {
    alert.onClose();
    deleteEvent.mutate(id);
  };
  const onClickDelete = (id: number) => {
    alert.onOpen({
      title: "Confirm Delete?",
      message: "Are you sure want to delete event?",
      actions: [
        {
          color: "error",
          variant: "contained",
          children: "Delete",
          onClick: () => onDelete(id),
        },
        {
          color: "primary",
          variant: "outlined",
          children: "Cancel",
          onClick: () => alert.onClose(),
        },
      ],
    });
  };

  const onHandleSearch = useCallback((values: { keyword: any }) => {
    setOptions((prev: any) => ({
      ...prev,
      keyword: values.keyword,
    }));
  }, []);
  return (
    <MainLayout>
      <Helmet>
        <title>EVENT</title>
      </Helmet>

      <Breadcrumb
        label="Event"
        breadcrumbs={[{ label: "Event", href: "/app/event" }]}
      />

      <ProtectedPermissionPage acceptPermissions={[99]}>
        <Box sx={{ mt: 3 }}>
          <Stack direction="row" spacing={1}>
            <Controller
              name="keyword"
              control={form.control}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Input Keyword here"
                  {...field}
                  {...config.onEnter(() => {
                    form.handleSubmit(onHandleSearch)();
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => {
                          if (field.value) {
                            field.onChange("");
                            setOptions((prev: any) => ({
                              ...prev,
                              keyword: "",
                            }));
                          }
                        }}
                      >
                        {field.value ? (
                          <Close color="error" sx={{ cursor: "pointer" }} />
                        ) : (
                          <Search />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            <Button
              variant="outlined"
              onClick={form.handleSubmit(onHandleSearch)}
            >
              SEARCH
            </Button>
          </Stack>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              component={RouterLink}
              to="/app/event/create-event"
            >
              ADD EVENT
            </Button>
          </Stack>
        </Box>

        <Box sx={{ mt: 2 }}>
          <DataGrid
            autoHeight
            loading={listEvent.isLoading}
            getRowId={(record) => get(record, "id")}
            rows={get(listEvent, "data.data", [])}
            rowCount={get(listEvent, "data.data", []).length}
            page={options.page_number}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            disableSelectionOnClick
            onPageChange={(newPage) => {
              setOptions((prev: any) => ({ ...prev, page_number: newPage }));
            }}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            components={{
              NoRowsOverlay: CustomNoRowOverlay,
            }}
            columns={[
              {
                field: "name",
                headerName: "Name",
                flex: 1,
                filterable: false,
                minWidth: 150,
                renderCell: (params) => {
                  return (
                    <Stack onClick={() => handleOnCellClick(params)}>
                      <Typography color="blue">{params.value}</Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "date_from",
                headerName: "Start Date",
                flex: 1,
                filterable: false,
                minWidth: 150,
                renderCell: (params) => {
                  if (!params.value) {
                    return "-";
                  }

                  return moment(params.value).format("DD/MM/YYYY");
                },
              },
              {
                field: "date_to",
                headerName: "End Date",
                flex: 1,
                filterable: false,
                minWidth: 150,
                renderCell: (params) => {
                  if (!params.value) {
                    return "-";
                  }

                  return moment(params.value).format("DD/MM/YYYY");
                },
              },
              {
                field: "actions",
                headerName: "Action",
                sortable: false,
                filterable: false,
                width: 200,
                renderCell: (params) => {
                  return (
                    <Stack direction="row" spacing={1}>
                      <Button
                        variant="outlined"
                        color="primary"
                        component={RouterLink}
                        to={`/app/event/edit/${get(params, "row.id")}`}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => onClickDelete(params.row.id)}
                      >
                        Delete
                      </Button>
                    </Stack>
                  );
                },
              },
            ]}
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700,
              },
            }}
          />
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
