import { Close, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CookieJs from "js-cookie";
import get from "lodash/get";
import { useCallback, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { Link as RouterLink } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import CustomPagination from "../components/CustomPagination";
import MainLayout from "../components/MainLayout";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";
import ProtectedPermissionView from "../components/ProtectedPermissionView";
import ticket from "../services/ticket";
import config from "../utils/config";
import queryDefault from "../utils/queryDefault";
import useNotification from "../hooks/useNotification";
import { LoadingButton } from "@mui/lab";

export default function Ticket() {
  const user = JSON.parse(CookieJs.get("USER") || "");
  const [idDownload, setIdDownload] = useState(0);
  const [options, setOptions] = useState<any>({
    keyword: "",
    page_number: 0,
    per_page: config.DEFAULT_PAGE_ROWS,
    event_id: 0,
    username: "",
  });
  const listTicket = useQuery(
    ["tickets", options],
    ({ queryKey }) => {
      return ticket.getAllTicketByEventId({
        ...queryKey[1],
        page_number: get(queryKey, [1, "page_number"], 0) + 1,
        event_id: user.event_id,
      });
    },
    {
      ...queryDefault,
    }
  );

  const form = useForm({
    defaultValues: {
      keyword: "",
    },
  });

  const onHandleSearch = useCallback((values: { keyword: any }) => {
    setOptions((prev: any) => ({
      ...prev,
      keyword: values.keyword,
    }));
  }, []);

  const notification = useNotification();
  const download = useMutation("download", ticket.generatePDF, {
    onError: () => {
      notification.onOpen({
        message: "No design available",
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });
  const handleDownload = (params: any) => download.mutate(params);

  return (
    <MainLayout>
      <Helmet>
        <title>TICKET</title>
      </Helmet>

      <Breadcrumb
        label="Ticket"
        breadcrumbs={[{ label: "Event", href: "/app/event" }]}
      />

      <ProtectedPermissionPage acceptPermissions={[1, 2]}>
        <Box sx={{ mt: 3 }}>
          <Stack direction="row" spacing={1}>
            <Controller
              name="keyword"
              control={form.control}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Input Keyword here"
                  {...field}
                  {...config.onEnter(() => {
                    form.handleSubmit(onHandleSearch)();
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => {
                          if (field.value) {
                            field.onChange("");
                            setOptions((prev: any) => ({
                              ...prev,
                              keyword: "",
                              page: 0,
                              perPage: config.DEFAULT_PAGE_ROWS,
                            }));
                          }
                        }}
                      >
                        {field.value ? (
                          <Close color="error" sx={{ cursor: "pointer" }} />
                        ) : (
                          <Search />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Button
              variant="outlined"
              onClick={form.handleSubmit(onHandleSearch)}
            >
              Search
            </Button>
            <Button variant="outlined" onClick={() => listTicket.refetch()}>
              Refresh
            </Button>
            <ProtectedPermissionView acceptPermissions={[1]}>
              <Button variant="outlined" onClick={() => ticket.getTicketReport(user.event_id)}>
                Download Report
              </Button>
            </ProtectedPermissionView>
          </Stack>
        </Box>

        <Box sx={{ mt: 2 }}>
          <DataGrid
            autoHeight
            loading={listTicket.isLoading}
            getRowId={(record) => get(record, "id")}
            rows={get(listTicket, "data.data", [])}
            rowCount={get(listTicket, "data.countData", 0)}
            page={options.page_number}
            pageSize={options.per_page}
            rowsPerPageOptions={config.DEFAULT_PAGE_SIZE}
            paginationMode="server"
            disableSelectionOnClick
            onPageChange={(newPage) => {
              setOptions((prev: any) => ({ ...prev, page_number: newPage }));
            }}
            onPageSizeChange={(newPageSize) => {
              setOptions((prev: any) => ({
                ...prev,
                per_page: newPageSize,
                page_number: 0,
              }));
            }}
            components={{
              Pagination: CustomPagination,
              NoRowsOverlay: noRowsOverlay,
            }}
            columns={[
              {
                field: "id",
                headerName: "ID",
                flex: 1,
                sortable: false,
                filterable: false,
                minWidth: 150,
              },
              {
                field: "orderid",
                headerName: "Order ID",
                flex: 1,
                sortable: false,
                filterable: false,
                minWidth: 150,
              },
              {
                field: "ticket_id",
                headerName: "Ticket ID",
                flex: 1,
                sortable: false,
                filterable: false,
                minWidth: 150,
              },
              {
                field: "is_redeemed",
                headerName: "Redeemed",
                flex: 1,
                sortable: false,
                filterable: false,
                minWidth: 150,
                renderCell: (params) => {
                  return params.value === true ?
                    <Typography
                      variant="body2"
                      color="green"
                      fontWeight="700"
                      sx={{ mt: 0.3 }}
                    >
                      Redeemed
                    </Typography>
                    : <Typography
                      variant="body2"
                      color="red"
                      fontWeight="700"
                      sx={{ mt: 0.3 }}
                    >
                      Not-Redeemed
                    </Typography>
                },
              },
              {
                field: "category",
                headerName: "Category",
                flex: 1,
                sortable: false,
                filterable: false,
                minWidth: 150,
              },
              {
                field: "name",
                headerName: "Name",
                flex: 1,
                sortable: false,
                filterable: false,
                minWidth: 150,
              },
              {
                field: "email",
                headerName: "Email",
                flex: 1,
                sortable: false,
                filterable: false,
                minWidth: 150,
              },
              {
                field: "actions",
                headerName: "Action",
                sortable: false,
                filterable: false,
                width: 200,
                renderCell: (params) => {
                  return (
                    <Stack direction="row" spacing={1}>
                      <Button
                        variant="outlined"
                        color="primary"
                        component={RouterLink}
                        to={`/app/ticket/${get(params, "row.id")}`}
                      >
                        Detail
                      </Button>
                      <ProtectedPermissionView acceptPermissions={[1]}>
                        <LoadingButton
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            setIdDownload(get(params, "row.ticket_id"));
                            handleDownload({
                              id: get(params, "row.ticket_id"),
                              name: get(params, "row.name")
                            });
                          }}
                          loading={download.isLoading && idDownload === get(params, "row.ticket_id")}
                        >
                          Invitation
                        </LoadingButton>
                      </ProtectedPermissionView>
                    </Stack>
                  );
                },
              },
            ]}
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700,
              },
            }}
          />
        </Box>
      </ProtectedPermissionPage>

    </MainLayout>
  );
}
function noRowsOverlay() {
  return (
    <Box sx={{ pt: 5 }}>
      <Typography variant="body1" textAlign="center" fontWeight="600">
        Sorry, data is not found
      </Typography>
    </Box>
  );
}
