export const API_URL = process.env.REACT_APP_BACKEND_URL
const endpoints = {
  // Auth
  SIGN_IN: API_URL + "/api/v1/auth/signin",
  PROFILE: (userId: any) => API_URL + `/api/v1/users/${userId}`,
  FORGOT_PASSWORD: API_URL + "/api/v1/admin/forgot-password",
  RESET_PASSWORD: API_URL + "/api/v1/admin/reset-password",
  EDIT_PROFILE: (userId: any) => API_URL + `/api/v1/users/${userId}`,
  CHANGE_PASSWORD: API_URL + "/api/v1/users/change-password",

  // Role
  VIEW_LIST_ROLE: API_URL + "/api/v1/roles",
  // Contact US
  CONTACT_US_LIST: API_URL + "/api/v1/help-center/contact-us",
  VIEW_CONTACT: (viewId: any) => API_URL + `/api/v1/help-center/contact-us/${viewId}`,

  // Users
  VIEW_LIST_USER: API_URL + "/api/v1/users",
  SEARCH_LIST_USER: API_URL + "/api/v1/admin/search-users",
  CREATE_USER: API_URL + "/api/v1/users",
  VIEW_USER: (viewId: any) => API_URL + `/api/v1/users/${viewId}`,

  // Delete Image
  DELETE_IMAGE: (tableId: number, columnId: number) => API_URL + `/api/v1/delete-image/${tableId}/${columnId}`,

  // Events
  EVENT: API_URL + "/api/v1/events",
  CREATE_EVENT: API_URL + "/api/v1/events",
  VIEW_EVENT: (id: number) => API_URL + `/api/v1/events/${id}`,
  DELETE_EVENT: (id: number) => API_URL + `/api/v1/events/${id}`,

  // Category
  CATEGORY:  API_URL + `/api/v1/categories`,
  CATEGORY_DETAIL: (id: number) => API_URL + `/api/v1/categories/${id}`,
  CATEGORY_PDF: API_URL + `/api/v1/categories/pdf`,
  CATEGORY_PDF_ONLY_QR: API_URL + `/api/v1/categories/pdfonlyqr`,
  CATEGORY_RESET: API_URL + `/api/v1/categories/reset`,
  CATEGORY_RESET_REDEEM: API_URL + `/api/v1/categories/resetredeem`,
  UPLOAD_IMAGE: API_URL + "/api/v1/categories/upload",
  UPLOAD_POSTER: API_URL + "/api/v1/categories/uploadPoster",
  UPLOAD_HEADER: API_URL + "/api/v1/categories/uploadHeader",
  UPLOAD_TEMPLATE: API_URL + "/api/v1/categories/uploadTemplate",
  ADDITIONAL_WRISTBAND: API_URL + "/api/v1/categories/additionalwristband",
  UPDATE_SHOWED_NAME: API_URL + "/api/v1/categories/showedname",
  WRISTBAND_IDS: (id: number) => API_URL + `/api/v1/categories/wristbands/${id}`,
  REDEEM_ACTIVE_STATUS: (id: number) => API_URL + `/api/v1/categories/redeemstatus/${id}`,
  CHECKIN_ACTIVE_STATUS: (id: number) => API_URL + `/api/v1/categories/checkinstatus/${id}`,
  BULK_INVITATION: API_URL + "/api/v1/bulk-invitation",

  // Ticket
  TICKET: API_URL + "/api/v1/tickets",
  VIEW_TICKET: (id: number) => API_URL + `/api/v1/tickets/${id}`,
  UPLOAD_TICKET: API_URL + "/api/v1/tickets/upload",
  REDEEM: API_URL + "/api/v1/redeem",
  CHECKIN: API_URL + "/api/v1/checkin",
  CHECKOUT: API_URL + "/api/v1/checkout",
  RESET_TICKET: API_URL + "/api/v1/tickets/reset-checkin",
  TICKET_REPORT: (id: number) => API_URL + `/api/v1/tickets/report/${id}`,
  TICKET_PDF: API_URL + `/api/v1/tickets/pdf`,

  //log
  REDEEM_LOG: (id: number) => API_URL + `/api/v1/redeem/${id}`,
  REDEEM_LOGS: API_URL + `/api/v1/redeem/log`,
  CHECKIN_LOG: (id: number) => API_URL + `/api/v1/checkin/${id}`,
  CHECKIN_LOGS: API_URL + `/api/v1/checkin/log`,
  CHECKOUT_STATUS: (id: number) => API_URL + `/api/v1/events/checkout-status/${id}`,
  CHANGE_CHECKOUT_STATUS: (id: number) => API_URL + `/api/v1/checkout/${id}`,
  DOWNLOAD_EXCEL: API_URL + `/api/v1/events/download`,

  //coupon
  COUPON_ALFAMART: API_URL + `/api/v1/coupon/alfamart`,
  COUPON_INDOMARET: API_URL + `/api/v1/coupon/indomaret`
};

export default endpoints;
