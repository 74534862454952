import axios from "axios";
import endpoints from "../utils/endpoints";
import { accessToken } from "../utils/token";
import fileDownload from "js-file-download";

const getAllTicketByEventId = async (params: any) => {
  const url = endpoints.TICKET;

  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const uploadTicket = async (params: any) => {
  const url = endpoints.UPLOAD_TICKET;
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const viewTicketDetail = async (id: any) => {
  if (parseInt(id)) {
    const url = endpoints.VIEW_TICKET(id);
    const { data: response } = await axios.get(url, {
      headers: {
        Authorization: accessToken,
      },
    });
    return response;
  }
};

const resetTicket = async (category_id: number) => {
  const url = endpoints.RESET_TICKET;
  const { data: response } = await axios.post(
    url,
    { category_id },
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );
  return response;
};

const getTicketReport = async (id: any) => {
  if (parseInt(id)) {
    const url = endpoints.TICKET_REPORT(id);
    await axios
      .get(url, {
        headers: {
          Authorization: accessToken,
          ContentType:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "arraybuffer",
      })
      .then((res) => {
        var blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        fileDownload(blob, "report_ticket.xlsx");
      });
  }
};

const generatePDF = async (params: any) => {
  const url = endpoints.TICKET_PDF;
  const param = { ticketId: params.id };
  await axios
    .post(url, param, {
      headers: {
        Authorization: accessToken,
        ContentType: "application/pdf",
      },
      responseType: "arraybuffer",
    })
    .then((res) => {
      var blob = new Blob([res.data], { type: "application/pdf" });
      fileDownload(blob, `invitation_${params.name}.pdf`);
    });
};

const displayPDF = async (params: any) => {
  const url = endpoints.TICKET_PDF;
  const param = { ticketId: params.id };
  const response = await axios.post(url, param, {
    headers: {
      Authorization: accessToken,
      ContentType: "application/pdf",
    },
    responseType: "arraybuffer",
  });
  const blob = new Blob([response.data], { type: "application/pdf" });
  return blob;
};

const service = {
  getAllTicketByEventId,
  uploadTicket,
  viewTicketDetail,
  resetTicket,
  getTicketReport,
  generatePDF,
  displayPDF,
};

export default service;
