import { Close, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CookieJs from 'js-cookie';
import get from "lodash/get";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import CustomPagination from "../components/CustomPagination";
import MainLayout from "../components/MainLayout";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";
import event from "../services/event";
import config from "../utils/config";
import queryDefault from "../utils/queryDefault";

function noRowsOverlay() {
  return (
    <Box sx={{ pt: 5 }}>
      <Typography variant="body1" textAlign="center" fontWeight="600">
        Sorry, data is not found
      </Typography>
    </Box>
  );
}

export default function RedeemLogs() {
  const user = JSON.parse(CookieJs.get("USER") || "");
  const location = useLocation();
  const path = location.pathname.split("/");
  const event_id = path.pop() || "";
  const [options, setOptions] = useState<any>({
    event_id: 0,
    keyword: "",
    page_number: 0,
    per_page: config.DEFAULT_PAGE_ROWS,
  });

  const listLog = useQuery(
    ["redeemLogs", options],
    ({ queryKey }) => {
      return event.getRedeemLogs({
        ...queryKey[1],
        page_number: get(queryKey, [1, "page_number"], 0) + 1,
        event_id: event_id
      });
    },
    {
      ...queryDefault,
    }
  );
  useEffect(() => {
    if (user) {
      if (!user.is_admin) {
        window.location.href = `/app/event/${user.event_id}`
      }
    }
  }, [user])

  const form = useForm({
    defaultValues: {
      keyword: "",
    },
  });

  const onHandleSearch = useCallback((values: { keyword: any }) => {
    setOptions((prev: any) => ({
      ...prev,
      keyword: values.keyword,
    }));
  }, []);

  return (
    <MainLayout>
      <Helmet>
        <title>Redeem Logs</title>
      </Helmet>

      <Breadcrumb
        label="Redeem Logs"
        breadcrumbs={[{ label: "Event", href: "/app/event" }]}
        href={`/app/event/${event_id}`}
      />

      <ProtectedPermissionPage acceptPermissions={[1]}>
        <Box sx={{ mt: 3 }}>
          <Stack direction="row" spacing={1}>
            <Controller
              name="keyword"
              control={form.control}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Input Keyword here"
                  {...field}
                  {...config.onEnter(() => {
                    form.handleSubmit(onHandleSearch)();
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => {
                          if (field.value) {
                            field.onChange("");
                            setOptions((prev: any) => ({
                              ...prev,
                              keyword: "",
                            }));
                          }
                        }}
                      >
                        {field.value ? (
                          <Close color="error" sx={{ cursor: "pointer" }} />
                        ) : (
                          <Search />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            <Button
              variant="outlined"
              onClick={form.handleSubmit(onHandleSearch)}
            >
              SEARCH
            </Button>
          </Stack>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Stack direction="row" spacing={1}>
            <Button
              variant="outlined"
              onClick={() => event.downloadRedeemLogs(event_id)}
            >
              Download
            </Button>
          </Stack>
        </Box>

        <Box sx={{ mt: 2 }}>
          <DataGrid
            autoHeight
            loading={listLog.isLoading}
            getRowId={(record) => get(record, "id")}
            rows={get(listLog, "data.data", [])}
            rowCount={get(listLog, "data.countData", 0)}
            page={options.page_number}
            pageSize={options.per_page}
            rowsPerPageOptions={config.DEFAULT_PAGE_SIZE}
            paginationMode="server"
            disableSelectionOnClick
            onPageChange={(newPage) => {
              setOptions((prev: any) => ({ ...prev, page_number: newPage }));
            }}
            onPageSizeChange={(newPageSize) => {
              setOptions((prev: any) => ({
                ...prev,
                per_page: newPageSize,
                page_number: 0,
              }));
            }}
            components={{
              Pagination: CustomPagination,
              NoRowsOverlay: noRowsOverlay,
            }}
            columns={[
              {
                field: "ticket_id",
                headerName: "Ticket Id",
                flex: 1,
                filterable: false,
                minWidth: 200,
                renderCell: (params) => {
                  return (
                    <Stack>
                      <Typography>{params.value}</Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "message",
                headerName: "Message",
                flex: 1,
                filterable: false,
                minWidth: 500,
                renderCell: (params) => {
                  return (
                    <Stack>
                      <Typography>{params.value}</Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "log_type",
                headerName: "Log Type",
                flex: 1,
                filterable: false,
                minWidth: 100,
                renderCell: (params) => {
                  return (
                    <Stack>
                      <Typography>{params.value}</Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "created_time",
                headerName: "Created Time",
                flex: 1,
                filterable: false,
                minWidth: 200,
                renderCell: (params) => {
                  if (!params.value) {
                    return "-";
                  }

                  return moment(params.value).format("YYYY-MM-DD HH:mm:ss");
                },
              },
              {
                field: "category",
                headerName: "Category",
                flex: 1,
                filterable: false,
                minWidth: 300,
                renderCell: (params) => {
                  return (
                    <Stack>
                      <Typography>{params.value}</Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "username",
                headerName: "Redeemed By",
                flex: 1,
                filterable: false,
                minWidth: 150,
                renderCell: (params) => {
                  return (
                    <Stack>
                      <Typography>{params.value}</Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "name",
                headerName: "Name",
                flex: 1,
                filterable: false,
                minWidth: 300,
                renderCell: (params) => {
                  return (
                    <Stack>
                      <Typography>{params.value}</Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "email",
                headerName: "Email",
                flex: 1,
                filterable: false,
                minWidth: 300,
                renderCell: (params) => {
                  return (
                    <Stack>
                      <Typography>{params.value}</Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "phone",
                headerName: "Phone",
                flex: 1,
                filterable: false,
                minWidth: 200,
                renderCell: (params) => {
                  return (
                    <Stack>
                      <Typography>{params.value}</Typography>
                    </Stack>
                  );
                },
              },
            ]}
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700,
              },
            }}
          />
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
