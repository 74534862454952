import axios from "axios";
import fileDownload from 'js-file-download';
import endpoints from "../utils/endpoints";
import { accessToken } from "../utils/token";

const generateCouponAlfamart = async () => {
  const url = endpoints.COUPON_ALFAMART;
  await axios.get(
    url,
    {
      headers: {
        Authorization: accessToken,
        ContentType: 'application/pdf',
      },
      responseType: 'arraybuffer',
    }
  ).then((res) => {
    var blob = new Blob([res.data], { type: 'application/pdf' });
    fileDownload(blob, 'coupon_alfamart.pdf');
  });
};

const generateCouponIndomaret = async () => {
  const url = endpoints.COUPON_INDOMARET;
  await axios.get(
    url,
    {
      headers: {
        Authorization: accessToken,
        ContentType: 'application/pdf',
      },
      responseType: 'arraybuffer',
    }
  ).then((res) => {
    var blob = new Blob([res.data], { type: 'application/pdf' });
    fileDownload(blob, 'coupon_indomaret.pdf');
  });
};

const service = {
  generateCouponAlfamart,
  generateCouponIndomaret
};

export default service;
