import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { ButtonProps } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
    textAlign: "center",
  },
  "& .MuiDialog-paper": {
    borderRadius: "8px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 4,
            top: 4,
            color: (theme) => theme.palette.grey[600],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface AlertProps {
  open: boolean;
  title: string;
  message: string;
  actions: ButtonProps[];
  onClose: () => void;
}

export default function Alert(props: AlertProps) {
  const { open, onClose, title, message, actions } = props;

  return (
    <BootstrapDialog onClose={onClose} open={open}>
      <BootstrapDialogTitle onClose={onClose} />
      <DialogContent>
        <Typography gutterBottom variant="h1">
          {title}
        </Typography>

        <Typography variant="body1" color="grey.600">
          {message}
        </Typography>

        <Stack
          direction="row"
          spacing={1}
          justifyContent="center"
          sx={{ mt: 2 }}
        >
          {actions.map((item, i) => (
            <LoadingButton {...item} key={i}>
              {item.children}
            </LoadingButton>
          ))}
        </Stack>
      </DialogContent>
    </BootstrapDialog>
  );
}

Alert.defaultProps = {
  actions: [],
};
