import { Box, Theme } from "@mui/material";
import { Outlet } from "react-router-dom";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginRight: "auto",
    marginLeft: "auto",
    maxWidth: 'none',

    [theme.breakpoints.down("sm")]: {
      maxWidth: 425,
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
}));

export default function MinimalLayout() {
  const classes = useStyles();

  return (
    <Box className={classes.container} id="minimal">
      <Outlet />
    </Box>
  );
}
