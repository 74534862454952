import { Search } from "@mui/icons-material";
import { Box, Button, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import bg from "../assets/images/bgvolunteer_lepi.png";
import bgHp from "../assets/images/bgvolunteer_hp.png";
import diterimaBig from "../assets/images/diterima_lepi.png";
import ditolakBig from "../assets/images/ditolak_lepi.png";
import diterimaSmall from "../assets/images/diterima_hp.png";
import ditolakSmall from "../assets/images/ditolak_hp.png";
import service from "../services/event";
import config from "../utils/config";

export default function CheckVolunteer() {
  const params = useParams();
  const [data, setData] = useState({
    isError: false,
    isSearched: false,
    name: '',
    position: '',
    email: '',
    mobile_no: '',
  });

  const form = useForm({
    defaultValues: {
      keyword: "",
    },
  });

  const check = (values: { keyword: any }) => {
    if (values.keyword === "") {
      setData({
        isError: false,
        isSearched: false,
        name: '',
        position: '',
        email: '',
        mobile_no: '',
      });
      return;
    }
    service.getVolunteer({
      email: values.keyword,
      eventId: params.id
    }).then((response) => {
      console.log(response);
      setData({
        ...response.data,
        id: params.id,
        isSearched: true,
      });
    }).catch((err) => {
      setData((prev: any) => ({
        ...prev,
        isError: true,
        isSearched: true,
      }));
    });
  }

  useEffect(() => {
    var root = document.getElementById("root");
    root!.style.backgroundImage = `url("${bg}")`;
    if (window.innerWidth < 768) {
      root!.style.backgroundImage = `url("${bgHp}")`;
      var diterima = document.getElementById("diterima");
      diterima!.style.backgroundImage = `url("${diterimaSmall}")`;
      diterima!.style.aspectRatio = `948/1178`;
      var diterimaName = document.getElementById("diterima-name");
      diterimaName!.style.top = "48.5%";
      diterimaName!.style.left = "8.6%";
      diterimaName!.style.fontSize = "4vw";
      diterimaName!.style.maxWidth = "80%";
      var diterimaPosition = document.getElementById("diterima-position");
      diterimaPosition!.style.top = "56%";
      diterimaPosition!.style.left = "8.6%";
      diterimaPosition!.style.fontSize = "4vw";
      diterimaPosition!.style.maxWidth = "80%";
      var diterimaEmail = document.getElementById("diterima-email");
      diterimaEmail!.style.top = "64.5%";
      diterimaEmail!.style.left = "8.6%";
      diterimaEmail!.style.fontSize = "4vw";
      diterimaEmail!.style.maxWidth = "80%";
      var diterimaMobile = document.getElementById("diterima-mobile");
      diterimaMobile!.style.top = "72.5%";
      diterimaMobile!.style.left = "8.6%";
      diterimaMobile!.style.fontSize = "4vw";
      diterimaMobile!.style.maxWidth = "80%";
      var ditolak = document.getElementById("ditolak");
      ditolak!.style.backgroundImage = `url("${ditolakSmall}")`;
      ditolak!.style.aspectRatio = `948/1178`;
      var ditolakName = document.getElementById("ditolak-name");
      ditolakName!.style.top = "48.5%";
      ditolakName!.style.left = "8.6%";
      ditolakName!.style.fontSize = "4vw";
      ditolakName!.style.maxWidth = "80%";
      var ditolakPosition = document.getElementById("ditolak-position");
      ditolakPosition!.style.top = "56%";
      ditolakPosition!.style.left = "8.6%";
      ditolakPosition!.style.fontSize = "4vw";
      ditolakPosition!.style.maxWidth = "80%";
      var ditolakEmail = document.getElementById("ditolak-email");
      ditolakEmail!.style.top = "64.5%";
      ditolakEmail!.style.left = "8.6%";
      ditolakEmail!.style.fontSize = "4vw";
      ditolakEmail!.style.maxWidth = "80%";
      var ditolakMobile = document.getElementById("ditolak-mobile");
      ditolakMobile!.style.top = "72.5%";
      ditolakMobile!.style.left = "8.6%";
      ditolakMobile!.style.fontSize = "4vw";
      ditolakMobile!.style.maxWidth = "80%";
    }
    var make = document.getElementById("minimal");
    make!.style.padding = "0";
    make!.style.margin = "0";
  }, []);

  return (
    <Box>
      <Helmet>
        <title>Check Volunteer</title>
      </Helmet>

      <Box sx={{
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        backgroundSize: "cover"
      }}>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            minWidth: "100px",
            maxWidth: "100vw",
            margin: "auto"
          }}
          display={data.isSearched ? "flex" : "none"}>
          <Typography
            sx={{
              backgroundColor: "white",
              padding: "40px",
              borderRadius: "10px"
            }}
            display={data.isError ? "block" : "none"}>
            Tidak ada data
          </Typography>
          <Box
            id="diterima"
            sx={{
              backgroundImage: `url("${diterimaBig}")`,
              width: "80vw",
              aspectRatio: "1518/768",
              backgroundSize: "contain"
            }}
            display={!data.isError && data.position !== "Belom Berkesempatan" ? "block" : "none"}>
            <Typography
              id="diterima-name"
              sx={{ position: 'relative', top: '42%', left: '5.1%', fontWeight: 'bolder', fontSize: '2vw', maxWidth: '40vw' }}>
              {data.name}
            </Typography>
            <Typography
              id="diterima-position"
              sx={{ position: 'relative', top: '55.5%', left: '5.1%', fontWeight: 'bolder', fontSize: '2vw', maxWidth: '40vw' }}>
              {data.position}
            </Typography>
            <Typography
              id="diterima-email"
              sx={{ position: 'relative', top: '69%', left: '5.1%', fontWeight: 'bolder', fontSize: '2vw', maxWidth: '40vw' }}>
              {data.email}
            </Typography>
            <Typography
              id="diterima-mobile"
              sx={{ position: 'relative', top: '22.5%', left: '69.4%', fontWeight: 'bolder', fontSize: '2vw', maxWidth: '34vw' }}>
              {data.mobile_no}
            </Typography>
          </Box>
          :
          <Box
            id="ditolak"
            sx={{
              backgroundImage: `url("${ditolakBig}")`,
              width: "80vw",
              aspectRatio: "1518/768",
              backgroundSize: "contain"
            }}
            display={!data.isError && data.position === "Belom Berkesempatan" ? "block" : "none"}>
            <Typography
              id="ditolak-name"
              sx={{ position: 'relative', top: '42%', left: '5.1%', fontWeight: 'bolder', fontSize: '2vw', maxWidth: '40vw' }}>
              {data.name}
            </Typography>
            <Typography
              id="ditolak-position"
              sx={{ position: 'relative', top: '55.5%', left: '5.1%', fontWeight: 'bolder', fontSize: '2vw', maxWidth: '40vw' }}>
              {data.position}
            </Typography>
            <Typography
              id="ditolak-email"
              sx={{ position: 'relative', top: '69%', left: '5.1%', fontWeight: 'bolder', fontSize: '2vw', maxWidth: '40vw' }}>
              {data.email}
            </Typography>
            <Typography
              id="ditolak-mobile"
              sx={{ position: 'relative', top: '22.5%', left: '69.4%', fontWeight: 'bolder', fontSize: '2vw', maxWidth: '34vw' }}>
              {data.mobile_no}
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ minWidth: "300px", margin: "auto" }}>
          <Controller
            name="keyword"
            control={form.control}
            render={({ field }) => (
              <TextField
                sx={{ width: "100%", background: "white", color: "black" }}
                variant="outlined"
                size="small"
                placeholder="Masukkan email kamu..."
                {...field}
                {...config.onEnter(() => form.handleSubmit(check)())}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() => {
                        if (field.value) {
                          check({ keyword: field.value });
                        }
                      }}
                    >
                      <Button sx={{ margin: 0 }}>
                        <Search />
                      </Button>

                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Stack>
      </Box>
    </Box>
  );
};
