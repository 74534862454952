import CookieJs from "js-cookie";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function useAutomaticLogIn() {
  const navigate = useNavigate();

  useEffect(() => {
    const getToken = async () => {
      const access: any = await CookieJs.get("ID_USER_ACCOUNT");

      if (access) {
        navigate("/app")
      }
    };

    getToken();
  }, []);
}
