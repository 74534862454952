import { LoadingButton } from "@mui/lab";
import {
  Box,
  Grid,
  Stack
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useMutation } from "react-query";
import MainLayout from "../components/MainLayout";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";
import ProtectedPermissionView from "../components/ProtectedPermissionView";
import coupon from "../services/coupon";

export default function Coupon() {
  const downloadAlfamart = useMutation(
    "download-coupon-alfamart",
    coupon.generateCouponAlfamart
  );
  const handleDownloadAlfamart = (params: any) => downloadAlfamart.mutate(params);
  
  const downloadIndomaret = useMutation(
    "download-coupon-indomaret",
    coupon.generateCouponIndomaret
  );
  const handleDownloadIndomaret = (params: any) => downloadIndomaret.mutate(params);

  return (
    <MainLayout>
      <Helmet>
        <title>Coupon</title>
      </Helmet>

      <ProtectedPermissionPage acceptPermissions={[1]}>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={{ lg: 4, md: 2 }}>
            <ProtectedPermissionView acceptPermissions={[99]}>
              <Grid item lg={4} md={12}>
                <Stack sx={{ mt: 2 }}>
                  <LoadingButton
                    variant="outlined"
                    color="primary"
                    onClick={handleDownloadAlfamart}
                    loading={downloadAlfamart.isLoading}
                  >
                    Generate Coupon Alfamart
                  </LoadingButton>
                </Stack>
                <Stack sx={{ mt: 2 }}>
                  <LoadingButton
                    variant="outlined"
                    color="primary"
                    onClick={handleDownloadIndomaret}
                    loading={downloadIndomaret.isLoading}
                  >
                    Generate Coupon Indomaret
                  </LoadingButton>
                </Stack>
              </Grid>
            </ProtectedPermissionView>
          </Grid>
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
