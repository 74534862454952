import { Close, Search } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CookieJs from "js-cookie";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { ReactComponent as ErrorLogo } from "../assets/images/error.svg";
import { ReactComponent as SuccessLogo } from "../assets/images/success.svg";
import Html5QrcodePlugin from "../components/Html5QrcodePlugin";
import MainLayout from "../components/MainLayout";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";
import scan from "../services/scan";
import config from "../utils/config";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#F2F2F2",
  border: "2px solid #6b4a94",
  boxShadow: 24,
  p: 4,
};

export default function Redeem() {
  let audio = new Audio("/beep.mp3");
  const checkQrShown = () => {
    const qr = document.getElementById("html5qr-code-full-region");
    if (qr?.innerHTML === "") {
      window.location.reload();
    }
  };

  setTimeout(checkQrShown, 500);
  const user = JSON.parse(CookieJs.get("USER") || "");
  const [data, setData] = useState({
    ticketId: "",
    message: "Start Scanning!",
    error: "",
    result: "",
    username: "",
    eventId: "",
    isSuccess: false,
    isError: false,
    name: "",
    pax: 0,
    found: false,
    category: "",
    wristband: "",
    seatNumber: "",
    is_link: false,
  });

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const onNewScanResult = (decodedText: string, decodedResult: any) => {
    document.getElementById("html5-qrcode-button-camera-stop")?.click();
    audio.play();
    scan
      .postRedeemTicket({
        ticketId: decodedText,
        username: user.username,
        eventId: user.event_id,
      })
      .then((response) => {
        setData((prev: any) => ({
          ...prev,
          ticketId: decodedText,
          message: response.message,
          error: "",
          isSuccess: true,
          isError: false,
          name: response.data.name,
          pax: response.data.ticket_count,
          found: response.found,
          category: response.data?.category,
          wristband: response.data?.unique_id,
          seatNumber: response.data?.seat_number,
          is_link: response.data?.is_link,
        }));
        handleOpen();
      })
      .catch((response) => {
        setData((prev: any) => ({
          ...prev,
          ticketId: decodedText,
          message: "Scan another ticket!",
          error: response.response.data.error,
          isSuccess: false,
          isError: true,
          name: response.response?.data?.data?.name,
          pax: response.response?.data?.data?.ticket_count,
          found: response.response?.data?.found,
          category: response.response?.data?.data?.category,
          wristband: response.response?.data?.data?.unique_id,
          seatNumber: response.response?.data?.data?.seat_number,
          is_link: response.response?.data?.data?.is_link,
        }));
        handleOpen();
      });
  };

  const closeSuccess = () =>
    setData((prev: any) => ({
      ...prev,
      isSuccess: false,
    }));

  const closeError = () =>
    setData((prev: any) => ({
      ...prev,
      isError: false,
    }));

  const redeemManual = (values: { keyword: any }) => {
    onNewScanResult(values.keyword, "");
  };

  const form = useForm({
    defaultValues: {
      keyword: "",
    },
  });

  return (
    <MainLayout>
      <Helmet>
        <title>Redeem Ticket</title>
      </Helmet>

      <ProtectedPermissionPage acceptPermissions={[1, 2]}>
        <Modal open={open} onClose={handleClose}>
          <Box sx={style} textAlign="center">
            <Stack direction="column" spacing={1}>
              {data.isSuccess && (
                <Typography>
                  <SuccessLogo />
                </Typography>
              )}
              {data.isSuccess && (
                <>
                  <Typography variant="h6">
                    TicketID: {data.ticketId}
                  </Typography>
                  <Typography variant="h6">Name: {data.name}</Typography>
                  <Typography variant="h6">Pax: {data.pax}</Typography>
                  <Typography variant="h6">
                    Category: {data.category}
                  </Typography>
                  {data.is_link && (
                    <>
                      <Typography variant="h6">
                        Wristband: {data.wristband}
                      </Typography>
                      <Typography variant="h6">
                        Seat Number:{" "}
                        {data.seatNumber !== "null" && data.seatNumber !== ""
                          ? data.seatNumber
                          : "-"}
                      </Typography>
                    </>
                  )}
                  <Typography variant="h6" marginTop="10">
                    {data.message}
                  </Typography>
                </>
              )}
              {data.isError && (
                <Typography>
                  <ErrorLogo />
                  {data.found && (
                    <>
                      <Typography variant="h6">
                        TicketID: {data.ticketId}
                      </Typography>
                      <Typography variant="h6">Name: {data.name}</Typography>
                      <Typography variant="h6">Pax: {data.pax}</Typography>
                      <Typography variant="h6">
                        Category: {data.category}
                      </Typography>
                      {data.is_link && (
                        <>
                          <Typography variant="h6">
                            Wristband: {data.wristband}
                          </Typography>
                          <Typography variant="h6">
                            Seat Number:{" "}
                            {data.seatNumber !== "null" &&
                            data.seatNumber !== ""
                              ? data.seatNumber
                              : "-"}
                          </Typography>
                        </>
                      )}
                    </>
                  )}
                  <Typography variant="h6">{data.error}</Typography>
                </Typography>
              )}
              <Button variant="outlined" onClick={handleClose}>
                Close
              </Button>
            </Stack>
          </Box>
        </Modal>

        <Typography variant="h2" textAlign="center" fontWeight="600">
          Redeem Ticket
        </Typography>

        {data.isSuccess && (
          <Typography textAlign="center">
            <SuccessLogo />
          </Typography>
        )}
        {data.isError && (
          <Typography textAlign="center">
            <ErrorLogo />
          </Typography>
        )}
        {data.isSuccess && (
          <Stack
            sx={{ width: "100%", marginTop: "10", marginBottom: "10" }}
            spacing={{ lg: 4, md: 2 }}
          >
            <Alert
              severity="success"
              onClose={() => {
                closeSuccess();
              }}
            >
              {data.message}
            </Alert>
          </Stack>
        )}
        {data.isError && (
          <Stack
            sx={{ width: "100%", marginTop: "10", marginBottom: "10" }}
            spacing={{ lg: 4, md: 2 }}
          >
            <Alert
              severity="error"
              onClose={() => {
                closeError();
              }}
            >
              {data.error}
            </Alert>
          </Stack>
        )}
        <Html5QrcodePlugin
          fps={10}
          qrbox={250}
          disableFlip={false}
          qrCodeSuccessCallback={onNewScanResult}
        />

        <Box sx={{ mt: 3 }}>
          <Stack direction="row" spacing={1}>
            <Controller
              name="keyword"
              control={form.control}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Input Ticket Id here"
                  {...field}
                  {...config.onEnter(() => {
                    form.handleSubmit(redeemManual)();
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => {
                          if (field.value) {
                            field.onChange("");
                          }
                        }}
                      >
                        {field.value ? (
                          <Close color="error" sx={{ cursor: "pointer" }} />
                        ) : (
                          <Search />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            <Button
              variant="outlined"
              onClick={form.handleSubmit(redeemManual)}
            >
              Redeem
            </Button>
          </Stack>
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
