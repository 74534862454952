import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import CookieJs from "js-cookie";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import MainLayout from "../components/MainLayout";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";
import ProtectedPermissionView from "../components/ProtectedPermissionView";
import useAlert from "../hooks/useAlert";
import useNotification from "../hooks/useNotification";
import category from "../services/category";
import initial from "../utils/initial";
import queryDefault from "../utils/queryDefault";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const chartSetting = { height: 300 };

export default function CategoryDetail() {
  const location = useLocation();
  const alert = useAlert();
  const path = location.pathname.split("/");
  const category_id = path.pop() || "";
  const [data, setData] = useState({
    id: "",
    name: "",
    redeem_total: 0,
    checkin_total: 0,
    first_checkin_total: 0,
    total_ticket: 0,
    additional_wristband: 0,
    showed_name: "",
    image: initial.IMAGE,
    event_id: "",
    refresh: false,
    venue: "",
    eventTime: "",
    promotor: "",
    poster: "",
    header: "",
    template: "",
    seating: ""
  });

  const [redeemStats, setRedeemStats] = useState([{ grouped_hours: "", count: "0" }]);
  const [checkinStats, setCheckinStats] = useState([{ grouped_hours: "", count: "0" }]);

  const formName = useForm({
    defaultValues: {
      name: "",
      venue: "",
      eventTime: "",
      promotor: "",
      seating: "",
    },
  });

  useEffect(() => {
    category.viewCategoryDetail(category_id).then((response) => {
      setData({
        id: category_id,
        redeem_total: response.data.redeem_total,
        checkin_total: response.data.checkin_total,
        first_checkin_total: response.data.first_checkin_total,
        total_ticket: response.data.ticket_count,
        additional_wristband: response.data.additional_wristband,
        showed_name: response.data.showed_name ? response.data.showed_name : "-",
        name: response.data.category,
        event_id: response.data.event_id,
        image:
          response.data.image === ""
            ? initial.IMAGE
            : "data:image/png;base64," + response.data.image,
        refresh: false,
        header: response.data.header === "" || response.data.header === null
          ? initial.IMAGEHEADER
          : "data:image/png;base64," + response.data.header,
        poster: response.data.poster === "" || response.data.poster === null
          ? initial.IMAGEPOSTER
          : "data:image/png;base64," + response.data.poster,
        template: response.data.template === "" || response.data.template === null
          ? initial.IMAGETEMPLATE
          : "data:image/png;base64," + response.data.template,
        venue: response.data.invitation_venue ? response.data.invitation_venue : "-",
        eventTime: response.data.invitation_time ? response.data.invitation_time : "-",
        promotor: response.data.invitation_promotor ? response.data.invitation_promotor : "-",
        seating: response.data.invitation_seating ? response.data.invitation_seating : "-",
      });
      formName.setValue("name", response.data.showed_name ? response.data.showed_name : "");
      formName.setValue("venue", response.data.invitation_venue ? response.data.invitation_venue : "");
      formName.setValue("eventTime", response.data.invitation_time ? response.data.invitation_time : "");
      formName.setValue("promotor", response.data.invitation_promotor ? response.data.invitation_promotor : "");
      formName.setValue("seating", response.data.invitation_seating ? response.data.invitation_seating : "");
      if (response.data.redeem_stats.length > 0) {
        for (const i of response.data.redeem_stats) {
          const date = new Date(i.grouped_hours);
          i.grouped_hours = `${date.getDate()}-${date.getHours()}`;
          i.count = Number(i.count);
        }
        setRedeemStats(response.data.redeem_stats);
      }
      if (response.data.checkin_stats.length > 0) {
        for (const i of response.data.checkin_stats) {
          const checkinDate = new Date(i.grouped_hours);
          i.grouped_hours = `${checkinDate.getDate()}-${checkinDate.getHours()}`;
          i.count = Number(i.count);
        }
        setCheckinStats(response.data.checkin_stats);
      }
    });
  }, [category_id, data.refresh, formName]);

  const download = useMutation("download", category.generatePDF, {
    onError: () => {
      notification.onOpen({
        message: "No design available",
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });
  const downloadOnlyQR = useMutation("download-only-qr", category.generatePDFOnlyQR);
  const downloadWristbandIds = useMutation("download-wristband-ids", category.getWristbandIds);
  const resetCategory = useMutation("reset-category", category.resetCategory);
  const resetRedeem = useMutation("reset-redeem", category.resetRedeem);
  const handleDownload = (params: any) => download.mutate(params);
  const handleDownloadOnlyQR = (params: any) => downloadOnlyQR.mutate(params);
  const handleDownloadWristbandIds = (params: any) => downloadWristbandIds.mutate(params);
  const handleReset = (arg0: number) => {
    resetCategory.mutate({ category_id: arg0 });
    alert.onClose();
    setData((prev: any) => ({ ...prev, refresh: true }));
  };
  const onReset = (arg0: any) => {
    alert.onOpen({
      title: "Confirm Reset",
      message: "Are you sure want to reset ticket?",
      actions: [
        {
          color: "error",
          variant: "contained",
          children: "Reset",
          onClick: () => handleReset(arg0),
        },
        {
          color: "primary",
          variant: "outlined",
          children: "Cancel",
          onClick: () => alert.onClose(),
        },
      ],
    });
  };
  const handleResetRedeem = (arg0: number) => {
    resetRedeem.mutate({ category_id: arg0 });
    alert.onClose();
    setData((prev: any) => ({ ...prev, refresh: true }));
  };
  const onResetRedeem = (arg0: any) => {
    alert.onOpen({
      title: "Confirm Reset",
      message: "Are you sure want to reset redeem?",
      actions: [
        {
          color: "error",
          variant: "contained",
          children: "Reset",
          onClick: () => handleResetRedeem(arg0),
        },
        {
          color: "primary",
          variant: "outlined",
          children: "Cancel",
          onClick: () => alert.onClose(),
        },
      ],
    });
  };

  const uploadImage = useMutation("upload_image", category.uploadImage, {
    onSuccess: () => window.location.reload()
  });

  const uploadPoster = useMutation("upload_poster", category.uploadPoster, {
    onSuccess: () => window.location.reload()
  });

  const uploadHeader = useMutation("upload_header", category.uploadHeader, {
    onSuccess: () => window.location.reload()
  });

  const uploadTemplate = useMutation("upload_template", category.uploadTemplate, {
    onSuccess: () => window.location.reload()
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    formData.append("category_id", data.id);
    event.target.files && formData.append("file", event.target.files[0]);
    uploadImage.mutate(formData);
  };

  const handlePosterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    formData.append("category_id", data.id);
    event.target.files && formData.append("file", event.target.files[0]);
    uploadPoster.mutate(formData);
  };

  const handleHeaderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    formData.append("category_id", data.id);
    event.target.files && formData.append("file", event.target.files[0]);
    uploadHeader.mutate(formData);
  };

  const handleTemplateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    formData.append("category_id", data.id);
    event.target.files && formData.append("file", event.target.files[0]);
    uploadTemplate.mutate(formData);
  };

  const [open, setOpen] = useState(false);
  const [openName, setOpenName] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenName = () => setOpenName(true);
  const handleCloseName = () => setOpenName(false);
  const form = useForm({
    defaultValues: {
      count: 0,
    },
  });
  const user = JSON.parse(CookieJs.get("USER") || "");
  const notification = useNotification();
  const generateAdditionalWristband = (values: { count: any }) => {
    category
      .additionalWristband({
        categoryId: category_id,
        count: values.count,
        username: user.username,
      })
      .then(() => {
        notification.onOpen({
          message: `Success generate ${values.count} wristband`,
          type: "success",
          position: "top",
        });
      })
      .catch(() => {
        notification.onOpen({
          message: `Failed generate additional wristband`,
          type: "error",
          position: "top",
        });
      });
    setOpen(false);
    setData((prev: any) => ({
      ...prev,
      refresh: true,
    }));
  };
  const updateShowedName = (values: {
    name: string;
    venue: string;
    eventTime: string;
    promotor: string;
  }) => {
    category
      .updateShowedName({
        categoryId: category_id,
        showed_name: values.name,
        username: user.username,
        venue: values.venue,
        eventTime: values.eventTime,
        promotor: values.promotor
      })
      .then(() => {
        notification.onOpen({
          message: `Success update the data`,
          type: "success",
          position: "top",
        });
      })
      .catch(() => {
        notification.onOpen({
          message: `Failed update data`,
          type: "error",
          position: "top",
        });
      });
    setOpenName(false);
    setData((prev: any) => ({
      ...prev,
      refresh: true,
    }));
  };

  const uploadBulk = useMutation("upload_ticket", category.uploadBulkInvitation, {
    onSuccess: () => {
      notification.onOpen({
        message: "Upload Bulk Success!",
        type: "success",
        position: "top",
      });
      window.location.reload();
    },
    onError: (error: any) => {
      notification.onOpen({
        message: error?.response?.data?.message,
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });
  const handleBulkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    formData.append("category_id", category_id);
    event.target.files && formData.append("file", event.target.files[0]);
    uploadBulk.mutate(formData);
  };

  return (
    <MainLayout>
      <Helmet>
        <title>Category</title>
      </Helmet>

      <Breadcrumb
        breadcrumbs={[{ label: "Event", href: "/app/event" }]}
        label={data.name}
        href={`/app/event/${data.event_id}`}
      />

      <ProtectedPermissionPage acceptPermissions={[1]}>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={{ lg: 4, md: 2 }}>
            <Grid item lg={4} md={12}>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Total Ticket
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.total_ticket}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Total First Check In
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.first_checkin_total}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Total Redeem
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.redeem_total}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Total Check In
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.checkin_total}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Additional Wristband
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.additional_wristband || 0}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Showed Name on Wristband
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.showed_name}
                </Typography>
              </Stack>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Invitation Promotor
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.promotor === "" ? "-" : data.promotor}
                </Typography>
              </Stack>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Invitation Time
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.eventTime === "" ? "-" : data.eventTime}
                </Typography>
              </Stack>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Invitation Venue
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.venue === "" ? "-" : data.venue}
                </Typography>
              </Stack>
            </Grid>

            <ProtectedPermissionView acceptPermissions={[99]}>
              <Grid item lg={4} md={12}>
                <Stack sx={{ mt: 2 }}>
                  <Button variant="outlined" component="label">
                    Upload Design Wristband <input onChange={handleFileChange} type="file" hidden />
                  </Button>
                </Stack>

                <Stack sx={{ mt: 2 }}>
                  <LoadingButton
                    variant="outlined"
                    color="primary"
                    onClick={() => handleDownload(data.id)}
                    loading={download.isLoading}
                  >
                    Generate Wristband PDF
                  </LoadingButton>
                </Stack>

                <Stack sx={{ mt: 2 }}>
                  <LoadingButton
                    variant="outlined"
                    color="primary"
                    onClick={() => handleDownloadOnlyQR(data.id)}
                    loading={downloadOnlyQR.isLoading}
                  >
                    Generate Wristband PDF Only QR
                  </LoadingButton>
                </Stack>

                <Stack sx={{ mt: 2 }}>
                  <Button
                    variant="outlined"
                    component="label"
                    onClick={handleOpen}
                  >
                    Generate Additional Wristband
                  </Button>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        Additional Wristband Count
                      </Typography>
                      <Box sx={{ mt: 3 }}>
                        <Stack direction="row" spacing={1}>
                          <Controller
                            name="count"
                            control={form.control}
                            render={({ field }) => (
                              <TextField
                                type="number"
                                variant="outlined"
                                size="small"
                                placeholder="Input Count"
                                {...field}
                                inputProps={{ min: 1 }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      onClick={() => {
                                        if (field.value) {
                                          field.onChange(0);
                                        }
                                      }}
                                    >
                                      <Close
                                        color="error"
                                        sx={{ cursor: "pointer" }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />

                          <Button
                            variant="outlined"
                            onClick={form.handleSubmit(
                              generateAdditionalWristband
                            )}
                          >
                            Generate
                          </Button>
                        </Stack>
                      </Box>
                    </Box>
                  </Modal>
                </Stack>

                <Stack sx={{ mt: 2 }}>
                  <LoadingButton
                    variant="outlined"
                    color="primary"
                    onClick={() => handleDownloadWristbandIds(data.id)}
                    loading={downloadWristbandIds.isLoading}
                  >
                    Download Wristband Ids
                  </LoadingButton>
                </Stack>

                <Stack sx={{ mt: 2 }}>
                  <Button variant="contained" component="label">
                    Upload Invitation Template <input onChange={handleTemplateChange} type="file" hidden />
                  </Button>
                </Stack>

                <Stack sx={{ mt: 2 }}>
                  <Button variant="contained" component="label">
                    Upload Invitation Header (2362 x 200 pixel) <input onChange={handleHeaderChange} type="file" hidden />
                  </Button>
                </Stack>

                <Stack sx={{ mt: 2 }}>
                  <Button variant="contained" component="label">
                    Upload Invitation Poster (841 x 395 pixel) <input onChange={handlePosterChange} type="file" hidden />
                  </Button>
                </Stack>

                <Stack sx={{ mt: 2 }}>
                  <Button
                    variant="contained"
                    component="label"
                    onClick={handleOpenName}
                  >
                    Update Wristband & Invitation Data
                  </Button>
                  <Modal
                    open={openName}
                    onClose={handleCloseName}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        Wristband Data
                      </Typography>
                      <Box sx={{ mt: 2, mb: 3 }}>
                        <Stack direction="row" spacing={1}>
                          <Controller
                            name="name"
                            control={formName.control}
                            render={({ field }) => (
                              <TextField
                                sx={{ minWidth: "90%" }}
                                variant="outlined"
                                size="small"
                                placeholder="Input Showed Name on Wristband"
                                {...field}
                                inputProps={{ maxLength: 30 }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      onClick={() => {
                                        if (field.value) {
                                          field.onChange("");
                                        }
                                      }}
                                    >
                                      <Close
                                        color="error"
                                        sx={{ cursor: "pointer" }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </Stack>
                      </Box>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        Invitation Data
                      </Typography>
                      <Box sx={{ mt: 2 }}>
                        <Stack direction="row" spacing={1}>
                          <Controller
                            name="eventTime"
                            control={formName.control}
                            render={({ field }) => (
                              <TextField
                                sx={{ minWidth: "90%" }}
                                variant="outlined"
                                size="small"
                                placeholder="Input Invitation Event Time"
                                {...field}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      onClick={() => {
                                        if (field.value) {
                                          field.onChange("");
                                        }
                                      }}
                                    >
                                      <Close
                                        color="error"
                                        sx={{ cursor: "pointer" }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </Stack>
                      </Box>
                      <Box sx={{ my: 3 }}>
                        <Stack direction="row" spacing={1}>
                          <Controller
                            name="venue"
                            control={formName.control}
                            render={({ field }) => (
                              <TextField
                                sx={{ minWidth: "90%" }}
                                variant="outlined"
                                size="small"
                                placeholder="Input Invitation Venue"
                                {...field}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      onClick={() => {
                                        if (field.value) {
                                          field.onChange("");
                                        }
                                      }}
                                    >
                                      <Close
                                        color="error"
                                        sx={{ cursor: "pointer" }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </Stack>
                      </Box>
                      <Box sx={{ my: 3 }}>
                        <Stack direction="row" spacing={1}>
                          <Controller
                            name="promotor"
                            control={formName.control}
                            render={({ field }) => (
                              <TextField
                                sx={{ minWidth: "90%" }}
                                variant="outlined"
                                size="small"
                                placeholder="Input Invitation Promotor"
                                {...field}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      onClick={() => {
                                        if (field.value) {
                                          field.onChange("");
                                        }
                                      }}
                                    >
                                      <Close
                                        color="error"
                                        sx={{ cursor: "pointer" }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </Stack>
                      </Box>
                      <Box sx={{ my: 3 }}>
                        <Stack direction="row" spacing={1}>
                          <Controller
                            name="seating"
                            control={formName.control}
                            render={({ field }) => (
                              <TextField
                                sx={{ minWidth: "90%" }}
                                variant="outlined"
                                size="small"
                                placeholder="Input Invitation Seating"
                                {...field}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      onClick={() => {
                                        if (field.value) {
                                          field.onChange("");
                                        }
                                      }}
                                    >
                                      <Close
                                        color="error"
                                        sx={{ cursor: "pointer" }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </Stack>
                      </Box>
                      <Button
                        variant="outlined"
                        onClick={formName.handleSubmit(updateShowedName)}
                      >
                        Update
                      </Button>
                    </Box>
                  </Modal>
                </Stack>

                <Stack sx={{ mt: 2 }}>
                  <Button variant="contained" component="label">
                    Upload Bulk Invitation
                    <input onChange={handleBulkChange} type="file" hidden />
                  </Button>
                  <Button
                    sx={{ mt: 2 }}
                    variant="contained"
                    color="primary"
                    component={RouterLink}
                    to={`/UploadBulkInvitationTemplate.xlsx`}
                    target="_blank"
                  >
                    Download Template Bulk Invitation
                  </Button>
                </Stack>

                <Stack sx={{ mt: 2 }}>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => onReset(data.id)}
                  >
                    Reset Checkin
                  </Button>
                </Stack>

                <Stack sx={{ mt: 2 }}>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => onResetRedeem(data.id)}
                  >
                    Reset Redeem
                  </Button>
                </Stack>
              </Grid>
            </ProtectedPermissionView>
          </Grid>
          <Grid container spacing={{ lg: 4, md: 2 }}>
            <Grid item lg={4} md={12}>
              <Stack sx={{ mt: 7 }}>
                <img src={data.image} alt="Wristband" width="800px" />
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={{ lg: 4, md: 2 }}>
            <Grid item lg={4} md={12}>
              <Stack sx={{ mt: 7 }}>
                <img src={data.template} alt="Template" width="800px" />
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={{ lg: 4, md: 2 }}>
            <Grid item lg={4} md={12}>
              <Stack sx={{ mt: 7 }}>
                <img src={data.header} alt="Header" width="800px" />
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={{ lg: 4, md: 2 }}>
            <Grid item lg={4} md={12}>
              <Stack sx={{ mt: 7 }}>
                <img src={data.poster} alt="Poster" width="800px" />
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Grid marginTop="10px"></Grid>
        <Typography variant="h3">Redeem Statistics</Typography>
        <BarChart
          dataset={redeemStats}
          xAxis={[{ scaleType: "band", dataKey: "grouped_hours", label: "Date-Hour" }]}
          series={[{ dataKey: "count", label: "Total" }]}
          {...chartSetting}
        />
        <Grid marginTop="10px"></Grid>
        <Typography variant="h3">Checkin Statistics</Typography>
        <BarChart
          dataset={checkinStats}
          xAxis={[{ scaleType: "band", dataKey: "grouped_hours", label: "Date-Hour" }]}
          series={[{ dataKey: "count", label: "Total" }]}
          {...chartSetting}
        />
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
