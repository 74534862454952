import axios from "axios";
import fileDownload from 'js-file-download';
import endpoints from "../utils/endpoints";
import { accessToken } from "../utils/token";

const getCategoryByEventId = async (params: any) => {
  const url = endpoints.CATEGORY;

  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const viewCategoryDetail = async (id: any) => {
  const url = endpoints.CATEGORY_DETAIL(id);
  const { data: response } = await axios.get(
    url,
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );
  return response;
};

const updateCategory = async (params: any) => {
  const url = endpoints.VIEW_EVENT(params?.eventId);

  const { data: response } = await axios.patch(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const resetCategory = async (params: any) => {
  const url = endpoints.CATEGORY_RESET;

  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const resetRedeem = async (params: any) => {
  const url = endpoints.CATEGORY_RESET_REDEEM;

  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const generatePDF = async (id: any) => {
  const url = endpoints.CATEGORY_PDF;
  const params = { 'categoryId': id };
  await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
      ContentType: 'application/pdf',
    },
    responseType: 'arraybuffer',
  }).then((res) => {
    var blob = new Blob([res.data], { type: 'application/pdf' });
    fileDownload(blob, 'category_wristband.pdf');
  });
};

const generatePDFOnlyQR = async (id: any) => {
  const url = endpoints.CATEGORY_PDF_ONLY_QR;
  const params = { 'categoryId': id };
  await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
      ContentType: 'application/pdf',
    },
    responseType: 'arraybuffer',
  }).then((res) => {
    var blob = new Blob([res.data], { type: 'application/pdf' });
    fileDownload(blob, 'category_wristband_only_qr.pdf');
  });
};

const uploadImage = async (params: any) => {
  const url = endpoints.UPLOAD_IMAGE;
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const uploadPoster = async (params: any) => {
  const url = endpoints.UPLOAD_POSTER;
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const uploadHeader = async (params: any) => {
  const url = endpoints.UPLOAD_HEADER;
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const uploadTemplate = async (params: any) => {
  const url = endpoints.UPLOAD_TEMPLATE;
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const additionalWristband = async (params: any) => {
  const url = endpoints.ADDITIONAL_WRISTBAND;

  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const updateShowedName = async (params: any) => {
  const url = endpoints.UPDATE_SHOWED_NAME;

  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const getWristbandIds = async (id: any) => {
  if (parseInt(id)) {
    const url = endpoints.WRISTBAND_IDS(id);
    await axios
      .get(url, {
        headers: {
          Authorization: accessToken,
          ContentType:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "arraybuffer",
      })
      .then((res) => {
        var blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        fileDownload(blob, "wristband_ids.xlsx");
      });
  }
};

const changeRedeemStatus = async (params: any) => {
  const url = endpoints.REDEEM_ACTIVE_STATUS(params);

  const { data: response } = await axios.patch(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const changeCheckinStatus = async (params: any) => {
  const url = endpoints.CHECKIN_ACTIVE_STATUS(params);

  const { data: response } = await axios.patch(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const uploadBulkInvitation = async (params: any) => {
  const url = endpoints.BULK_INVITATION;
  await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
      ContentType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    responseType: "blob",
  }).then((res) => {
    var blob = new Blob([res.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fileDownload(blob, "bulk_invitation_links.xlsx");
  });
};

const service = {
  getCategoryByEventId,
  viewCategoryDetail,
  updateCategory,
  generatePDF,
  resetCategory,
  resetRedeem,
  uploadImage,
  generatePDFOnlyQR,
  additionalWristband,
  updateShowedName,
  getWristbandIds,
  changeRedeemStatus,
  changeCheckinStatus,
  uploadPoster,
  uploadHeader,
  uploadBulkInvitation,
  uploadTemplate
};

export default service;
