import axios from "axios";

import endpoints from "../utils/endpoints";
import { accessToken } from "../utils/token";

const getUsers = async (params: any) => {
  const url = endpoints.VIEW_LIST_USER;

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: params
  });

  return response;
};

const createUser = async (params: any) => {
  const url = endpoints.CREATE_USER;

  const { data: response } = await axios.post(url, params?.data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const viewUser = async (viewId: any) => {
  const url = endpoints.VIEW_USER(viewId);

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const editUser = async (params: any) => {
  const url = endpoints.EDIT_PROFILE(params?.data.id);

  const { data: response } = await axios.patch(url, params?.data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const deleteUser = async (userId: any) => {
  const url = endpoints.PROFILE(userId);

  const { data: response } = await axios.delete(url, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const service = {
  getUsers,
  createUser,
  editUser,
  viewUser,
  deleteUser
};

export default service;