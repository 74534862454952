import axios from "axios";
import get from "lodash/get";
import { actions } from "../store/reducers/global";
import CookieJs from "js-cookie";
import endpoints from "../utils/endpoints";
import { accessToken } from "../utils/token";

const signIn = async (params: any) => {
  const url = endpoints.SIGN_IN;
  try {
    const { data: response } = await axios.post(url, params);
    const profile = await getProfile(
      get(response, "data.id"),
      `Bearer ${get(response, "access_token")}`
    );
    return {
      ...response,
      profile,
    };
  } catch (error) {
    throw error;
  }
};

const signOut = () => {
  return async (dispatch: any) => {
    dispatch(actions.signOut());
    CookieJs.remove("ID_USER_ACCOUNT");
    CookieJs.remove("ID_USER");
    CookieJs.remove("USER");
    CookieJs.remove("ticketId");
    CookieJs.remove("wristbandCheckOutId");
    CookieJs.remove("wristbandCheckInId");
    window.location.href = "/";
  };
};

const getProfile = async (userId: any, access?: any) => {
  const url = endpoints.PROFILE(userId);

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken ? accessToken : access,
    },
  });

  return {
    ...get(response, "data"),
    userId,
  };
};

const editProfile = async (params: any) => {
  const url = endpoints.EDIT_PROFILE(params?.userId);

  const { data: response } = await axios.put(url, params?.data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const viewProfile = (userId: any) => {
  return async (dispatch: any) => {
    const url = endpoints.PROFILE(userId);

    try {
      const { data: response } = await axios.get(url, {
        headers: {
          Authorization: accessToken,
        },
      });

      dispatch(
        actions.getProfileSuccess({ ...get(response, "data"), userId })
      );
    } catch (error: any) {
      CookieJs.remove("ID_USER_ACCOUNT");
      CookieJs.remove("ID_USER");
      CookieJs.remove("USER");
      window.location.href = "/";
    }
  };
};

const changePassword = async (params: any) => {
  const url = endpoints.CHANGE_PASSWORD;

  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const forgotPassword = async (params: any) => {
  const url = endpoints.FORGOT_PASSWORD;

  const { data: response } = await axios.post(url, params);

  return response;
};

const resetPassword = async (params: any) => {
  const url = endpoints.RESET_PASSWORD;

  const { data: response } = await axios.put(url, params);

  return response;
};

const service = {
  signIn,
  signOut,
  getProfile,
  editProfile,
  forgotPassword,
  resetPassword,
  changePassword,
  viewProfile,
};

export default service;
