import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Link,
  TextField
} from "@mui/material";
import CookieJs from "js-cookie";
import get from "lodash/get";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import * as yup from "yup";
import Logo from "../assets/images/logo_ticket.png";
import TextFieldPassword from "../components/PasswordTextField";
import useAutomaticLogIn from "../hooks/useAutomaticLogIn";
import useNotification from "../hooks/useNotification";
import auth from "../services/auth";
import { actions } from "../store/reducers/global";

export default function SignInPage() {
  const notification = useNotification();
  const dispatch = useDispatch();

  useAutomaticLogIn();

  const schema = yup.object({
    username: yup.string().required(),
    password: yup.string().required(),
  });

  const form = useForm({
    defaultValues: {
      username: "",
      password: "",
      remember: true,
    },
    resolver: yupResolver(schema),
  });

  const { control, handleSubmit } = form;

  const signIn = useMutation("login", auth.signIn, {
    onSuccess: (data) => {
      const isActive = get(data, "profile.is_active", false);
      if (isActive) {
        dispatch(actions.signIn(get(data, "profile")));

        CookieJs.set("ID_USER_ACCOUNT", get(data, "access_token"));
        CookieJs.set("ID_USER", get(data, "data.id"));
        CookieJs.set("USER", JSON.stringify(data.data));
        window.location.href = "/app";
      } else {
        notification.onOpen({
          message: "sorry, your account has been deactivated",
          type: "error",
          position: "top",
        });
      }
    },
    onError(error) {
      const err = get(error, "response.data.error");

      if (err === "There is no data corresponding to the username.") {
        notification.onOpen({
          message: "Sorry, we didn`t recognise that account",
          type: "error",
          position: "top",
        });
      } else if (err === "Invalid email or password !!") {
        notification.onOpen({
          message: "Sorry your password was incorrect",
          type: "error",
          position: "top",
        });
      } else {
        notification.onOpen({
          message: "Something went wrong!",
          type: "error",
          position: "top",
        });
      }
    },
  });

  const onSubmit = (values: any) => {
    signIn.mutate(values);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Helmet>
        <title>Sign In</title>
      </Helmet>

      <Box sx={{ width: 300 }}>
        <Box sx={{ textAlign: "center" }}>
          <img src={Logo} style={{ height: 90 }} alt="signin"/>
        </Box>

        <Box component="form" sx={{ mt: 3 }} onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth>
            <FormLabel required>Username</FormLabel>
            <Controller
              name="username"
              control={control}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  size="small"
                  variant="outlined"
                  error={Boolean(fieldState?.error)}
                  helperText={fieldState.error?.message}
                  {...field}
                  inputRef={ref}
                />
              )}
            />
          </FormControl>

          <FormControl fullWidth sx={{ mt: 2 }}>
            <FormLabel required>Password</FormLabel>
            <Controller
              name="password"
              control={control}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextFieldPassword
                  size="small"
                  variant="outlined"
                  error={Boolean(fieldState?.error)}
                  helperText={fieldState.error?.message}
                  {...field}
                  inputRef={ref}
                />
              )}
            />
          </FormControl>

          <Box sx={{ mt: 2 }}>
            <Link
              component={RouterLink}
              to="/reset-password"
              fontWeight="700"
              underline="none"
            >
              Forgot Password?
            </Link>
          </Box>

          <FormControl sx={{ mt: 1 }}>
            <Controller
              name="remember"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox />}
                  label="Remember me"
                />
              )}
            />
          </FormControl>

          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            sx={{ mt: 1 }}
            loading={signIn.isLoading}
          >
            SIGN IN
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
}
