import { MantineProvider } from "@mantine/core";
import { RangeCalendar } from "@mantine/dates";
import { Close, DateRange } from "@mui/icons-material";
import {
  Box, Button, Dialog, InputAdornment, Stack,
  TextField
} from "@mui/material";
import Moment from "moment";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";

interface Props {
  fullWidth?: boolean;
  onApply: (options: any) => void;
  onClear?: () => void;
  date_from?: Date | null;
  date_to?: Date | null;
}

export default function DateRangePicker(props: Props) {
  const {
    onApply,
    onClear,
    fullWidth = false,
    date_from = null,
    date_to = null,
  } = props;
  const [open, setOpen] = useState(false);
  const form = useForm({
    defaultValues: {
      range: [date_from, date_to],
    },
  });
  const handleClose = () => {
    setOpen(false);
  };

  const value = form.watch("range");
  const isDisabled = value[0] && value[1];

  return (
    <Box>
      <TextField
        fullWidth={fullWidth}
        size="small"
        variant="outlined"
        placeholder="Input Date Range here"
        value={value
          ?.filter((i) => i)
          .map((ix) => Moment(ix).format("M/D/YYYY"))
          .join(" - ")}
        InputProps={{
          readOnly: true,
          inputProps: {
            onClick: () => setOpen(true),
          },
          endAdornment:
            value[0] && value[1] ? (
              <InputAdornment
                position="end"
                onClick={() => {
                  form.setValue("range", [null, null]);

                  if (typeof onClear === "function") onClear();
                }}
              >
                <Close color="error" />
              </InputAdornment>
            ) : (
              <InputAdornment position="end" onClick={() => setOpen(true)}>
                <DateRange />
              </InputAdornment>
            ),
        }}
      />

      <Dialog open={open} onClose={handleClose}>
        <Box sx={{ p: 2 }}>
          <MantineProvider theme={{ fontFamily: "Gotham" }}>
            <Controller
              name="range"
              control={form.control}
              render={({ field }: any) => (
                <RangeCalendar
                  value={field.value}
                  onChange={(date) => field.onChange(date)}
                />
              )}
            />
          </MantineProvider>

          <Box sx={{ mt: 2 }}>
            <Stack direction="row" justifyContent="end">
              <Button color="error" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                disabled={!isDisabled}
                onClick={() => {
                  onApply(value);
                  handleClose();
                }}
              >
                Apply
              </Button>
            </Stack>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
}
