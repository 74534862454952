const sideMenu = [
  {
    id: "events",
    label: "Events",
    href: "/app/event",
    permissions: [1]
  },
  {
    id: "tickets",
    label: "Tickets",
    href: "/app/ticket",
    permissions: [1, 2]
  },
  {
    id: "redeem",
    label: "Redeem",
    href: "/app/redeem",
    permissions: [1, 2]
  },
  {
    id: "checkin",
    label: "Check In",
    href: "/app/checkin",
    permissions: [1, 3]
  },
  {
    id: "checkout",
    label: "Check Out",
    href: "/app/checkout",
    permissions: [1, 4]
  },
  {
    id: "userlist",
    label: "User Management",
    href: "/app/users",
    permissions: [99]
  }
];

export default sideMenu;
