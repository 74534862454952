import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, Stack, Switch, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import CookieJs from "js-cookie";
import moment from "moment";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useMutation } from "react-query";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import MainLayout from "../components/MainLayout";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";
import ProtectedPermissionView from "../components/ProtectedPermissionView";
import useNotification from "../hooks/useNotification";
import { default as event, default as service } from "../services/event";
import ticket from "../services/ticket";
import queryDefault from "../utils/queryDefault";
import Category from "./Category";

export default function EventDetail() {
  const user = JSON.parse(CookieJs.get("USER") || "");
  const location = useLocation();
  const path = location.pathname.split("/");
  const event_id = path.pop() || "";
  const [checkoutStatus, setCheckoutStatus] = useState(true);
  const [redeemReport, setRedeemReport] = useState([
    { username: "", count: "0" },
  ]);
  const [checkinReport, setCheckinReport] = useState([
    { username: "", count: "0" },
  ]);
  const [redeemStats, setRedeemStats] = useState([
    { grouped_hours: "", count: "0" },
  ]);
  const [checkinStats, setCheckinStats] = useState([
    { grouped_hours: "", count: "0" },
  ]);
  const chartSetting = {
    height: 300,
  };
  const [data, setData] = useState({
    id: "",
    name: "",
    redeem_total: 0,
    checkin_total: 0,
    first_checkin_total: 0,
    total_ticket: 0,
    additional_wristband: 0,
    created_time: null,
    created_by: "",
    admin_name: "",
    phone_number: "",
    date_from: null,
    date_to: null,
    file_path: "",
    is_custom_wristband: false,
  });

  useEffect(() => {
    service.viewEventDetail(event_id).then((response) => {
      setData({
        id: event_id,
        redeem_total: response.data.redeem_total,
        checkin_total: response.data.checkin_total,
        first_checkin_total: response.data.first_checkin_total,
        total_ticket: response.data.total_ticket,
        additional_wristband: response.data.additional_wristband,
        name: response.data.name,
        created_time: response.data.created_time,
        created_by: response.data.created_by,
        admin_name: response.data.admin_name,
        phone_number: response.data.phone_number,
        date_from: response.data.date_from,
        date_to: response.data.date_to,
        file_path: response.data.file_path,
        is_custom_wristband: response.data.is_custom_wristband,
      });
      if (response.data.redeem_stats?.length > 0) {
        for (const i of response.data.redeem_stats) {
          var date = new Date(i.grouped_hours);
          i.grouped_hours = `${date.getDate()}-${date.getHours()}`;
          i.count = Number(i.count);
        }
      }
      if (response.data?.checkin_stats?.length > 0) {
        for (const i of response.data.checkin_stats) {
          var checkinDate = new Date(i.grouped_hours);
          i.grouped_hours = `${checkinDate.getDate()}-${checkinDate.getHours()}`;
          i.count = Number(i.count);
        }
      }
      if (response.data.redeem_stats.length > 0) {
        for (const i of response.data.redeem_stats) {
          i.count = Number(i.count);
        }
        setRedeemStats(response.data.redeem_stats);
      }
      if (response.data.checkin_stats.length > 0) {
        for (const i of response.data.checkin_stats) {
          i.count = Number(i.count);
        }
        setCheckinStats(response.data.checkin_stats);
      }
      setCheckoutStatus(response.data.ischeckout);
    });
    service
      .getReport({ reportType: "redeem", eventId: event_id })
      .then((response) => {
        if (response?.data.length > 0) {
          for (const i of response.data) {
            i.count = Number(i.count);
          }
          setRedeemReport(response.data);
        }
      });
    service
      .getReport({ reportType: "checkin", eventId: event_id })
      .then((response) => {
        if (response?.data.length > 0) {
          for (const i of response.data) {
            i.count = Number(i.count);
          }
          setCheckinReport(response.data);
        }
      });
  }, [checkoutStatus, event_id]);

  const handleChange = async () => {
    service.changeCheckoutStatus(event_id).then((response) => {
      setCheckoutStatus(response.data);
    });
  };
  const notification = useNotification();
  const uploadTicket = useMutation("upload_ticket", ticket.uploadTicket, {
    onSuccess: () => {
      notification.onOpen({
        message: "Ticket was successfully uploaded!",
        type: "success",
        position: "top",
      });
      window.location.reload();
    },
    onError: (error: any) => {
      notification.onOpen({
        message: error?.response?.data?.message,
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });
  const uploadVolunteer = useMutation("upload_volunteer", service.uploadVolunteer, {
    onSuccess: () => {
      notification.onOpen({
        message: "Volunteer was successfully uploaded!",
        type: "success",
        position: "top",
      });
      window.location.reload();
    },
    onError: (error: any) => {
      notification.onOpen({
        message: error?.response?.data?.message,
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    formData.append("event_id", event_id);
    event.target.files && formData.append("file", event.target.files[0]);
    formData.append("username", user.username);
    uploadTicket.mutate(formData);
  };
  const handleFileChangeVolunteer = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    formData.append("event_id", event_id);
    event.target.files && formData.append("file", event.target.files[0]);
    formData.append("username", user.username);
    uploadVolunteer.mutate(formData);
  };
  const download = useMutation("download", event.downloadFile);

  const handleDownload = () => download.mutate(data.file_path);

  return (
    <MainLayout>
      <Helmet>
        <title>Event</title>
      </Helmet>

      <Breadcrumb
        breadcrumbs={[{ label: "Event", href: "/app/event" }]}
        label={data.name}
        href={user.is_admin ? "/app/event" : ""}
      />

      <ProtectedPermissionPage acceptPermissions={[1]}>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={{ lg: 4, md: 2 }}>
            <Grid item lg={4} md={12}>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Created Time
                </Typography>
                <Typography
                  variant="body1"
                  color="grey.600"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {moment(data.created_time).format("D/MM/YYYY HH:mm")}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Created By
                </Typography>
                <Typography
                  variant="body1"
                  color="grey.600"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.created_by}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Event Start Date
                </Typography>
                <Typography
                  variant="body1"
                  color="grey.600"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {moment(data.date_from).format("D/MM/YYYY")}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Stack>
                  <Typography variant="body1" color="grey.600" fontWeight="300">
                    Event End Date
                  </Typography>
                  <Typography
                    variant="body1"
                    color="grey.600"
                    fontWeight="700"
                    sx={{ mt: 0.3 }}
                  >
                    {moment(data.date_to).format("D/MM/YYYY")}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>

            <Grid item lg={4} md={12}>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Total Ticket
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.total_ticket}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Total First Check In
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.first_checkin_total}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Total Redeem
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.redeem_total}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Total Check In
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.checkin_total}
                </Typography>
              </Stack>
            </Grid>

            <Grid item lg={4} md={12}>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Total Additional Wristband
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.additional_wristband}
                </Typography>
              </Stack>
              <Grid container spacing={{ lg: 2, md: 1 }}>
                <Grid item lg={6} md={12}>
                  <Stack sx={{ mt: 2 }}>
                    <Typography
                      variant="body1"
                      color="grey.600"
                      fontWeight="300"
                    >
                      Enable Checkout
                    </Typography>
                    <Switch
                      checked={checkoutStatus}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Stack>
                </Grid>
                <Grid item lg={6} md={12}>
                  <Stack sx={{ mt: 2 }}>
                    <Typography
                      variant="body1"
                      color="grey.600"
                      fontWeight="300"
                    >
                      Custom Wristband
                    </Typography>
                    <Typography
                      variant="body1"
                      color={data.additional_wristband ? "green" : "red"}
                      fontWeight="700"
                      sx={{ mt: 0.3 }}
                    >
                      {data.is_custom_wristband ? "Yes" : "No"}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>

              <Stack sx={{ mt: 2 }}>
                <Button
                  variant="outlined"
                  component={RouterLink}
                  to={`/app/event/redeem-logs/${data.id}`}
                >
                  Redeem Logs
                </Button>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Button
                  variant="outlined"
                  component={RouterLink}
                  to={`/app/event/checkin-checkout-logs/${data.id}`}
                >
                  Checkin/Checkout Logs
                </Button>
              </Stack>
            </Grid>
          </Grid>

          <ProtectedPermissionView acceptPermissions={[99]}>
            <Grid sx={{ mt: 2 }}>
              <Stack direction="row" spacing={1}>
                <Button variant="contained" component="label">
                  Upload Ticket
                  <input onChange={handleFileChange} type="file" hidden />
                </Button>
                <Button variant="contained" component="label">
                  Upload Volunteer
                  <input onChange={handleFileChangeVolunteer} type="file" hidden />
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to={`/UploadTemplateTicket.xlsx`}
                  target="_blank"
                >
                  Download Template
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to={`/TemplateVolunteerList.xlsx`}
                  target="_blank"
                >
                  Download Template Volunteer
                </Button>
                {data.file_path ? (
                  <Stack sx={{ mt: 2 }}>
                    <LoadingButton
                      variant="outlined"
                      color="primary"
                      onClick={() => handleDownload()}
                      loading={download.isLoading}
                    >
                      Download Latest Excel
                    </LoadingButton>
                  </Stack>
                ) : (
                  <></>
                )}
              </Stack>
            </Grid>
          </ProtectedPermissionView>
          <Category id={event_id} />
          <Grid marginTop="10px"></Grid>
          <Typography variant="h3">Redeem Graph</Typography>
          <BarChart
            dataset={redeemReport}
            xAxis={[{ scaleType: "band", dataKey: "username", label: "User" }]}
            series={[{ dataKey: "count", type: "bar" }]}
            {...chartSetting}
          />
          <Grid marginTop="10px"></Grid>
          <Typography variant="h3">Check In Graph</Typography>
          <BarChart
            dataset={checkinReport}
            xAxis={[
              {
                scaleType: "band",
                dataKey: "username",
                label: "User",
              },
            ]}
            series={[{ dataKey: "count", type: "bar" }]}
            {...chartSetting}
          />
          <Grid marginTop="10px"></Grid>
          <Typography variant="h3">Redeem Statistics</Typography>
          <BarChart
            dataset={redeemStats}
            xAxis={[
              {
                scaleType: "band",
                dataKey: "grouped_hours",
                label: "Date-Hour",
              },
            ]}
            series={[{ dataKey: "count", label: "Total" }]}
            {...chartSetting}
          />
          <Grid marginTop="10px"></Grid>
          <Typography variant="h3">Checkin Statistics</Typography>
          <BarChart
            dataset={checkinStats}
            xAxis={[
              {
                scaleType: "band",
                dataKey: "grouped_hours",
                label: "Date-Hour",
              },
            ]}
            series={[{ dataKey: "count", label: "Total" }]}
            {...chartSetting}
          />
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
