import {
  CheckCircleOutlineOutlined, ErrorOutlineOutlined, HighlightOffOutlined
} from "@mui/icons-material";
import { Alert, Snackbar } from "@mui/material";
import get from "lodash/get";

interface Notification {
  open: boolean;
  message: string;
  severity: any;
  position: string;
  actions?: any;
  onClose: () => void;
}

export default function Notification(props: Notification) {
  const { open, message, severity = "info", position, onClose } = props;

  const colors: any = {
    error: {
      background: "#FFE2E2",
      text: "#EB5757",
    },
    success: {
      background: "#EDFFF4",
      text: "#219653",
    },
    warning: {
      background: "#FFFBE8",
      text: "#F2994A",
    },
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={
        position === "bottom"
          ? { vertical: "bottom", horizontal: "center" }
          : { vertical: "top", horizontal: "center" }
      }
      autoHideDuration={3000}
      onClose={onClose}
    >
      <Alert
        severity={severity}
        iconMapping={{
          success: <CheckCircleOutlineOutlined fontSize="inherit" />,
          error: <HighlightOffOutlined fontSize="inherit" />,
          warning: <ErrorOutlineOutlined fontSize="inherit" />,
        }}
        sx={{
          borderRadius: "8px",
          backgroundColor: get(colors, [severity, "background"]),
          color: get(colors, [severity, "text"]),
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
