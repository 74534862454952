import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import ticketService from "../services/ticket";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useParams } from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/4.3.136/pdf.worker.min.mjs



`;

const PdfViewer: React.FC = () => {
  const params = useParams();
  const { id } = params;
  console.log(id);
  const [pdfData, setPdfData] = useState<string | null>(null);

  const fetchPdf = async () => {
    try {
      const response = await ticketService.displayPDF({id});
      const url = URL.createObjectURL(response);
      setPdfData(url);
    } catch (error) {
      console.error("Error fetching PDF:", error);
    }
  };

  useEffect(() => {
    fetchPdf();
  }, []);

  return (
    <div className="PdfViewer">
      {pdfData ? (
        <Document file={pdfData}>
          <Page pageNumber={1} />
        </Document>
      ) : (
        <p>Loading PDF...</p>
      )}
    </div>
  );
};

export default PdfViewer;
