import { Box, Switch, Typography } from "@mui/material";
import { DataGrid, GridEventListener } from "@mui/x-data-grid";
import get from "lodash/get";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import category from "../services/category";

const NoOverlay = () => (
  <Box sx={{ pt: 5 }}>
    <Typography variant="body1" textAlign="center" fontWeight="600">
      Please upload the ticket list to generate category
    </Typography>
  </Box>
);

export default function Category(params: any) {
  const { id } = params;
  const listCategory = useQuery("list-category", () =>
    category.getCategoryByEventId({ event_id: id })
  );
  const navigate = useNavigate();
  const handleEvent: GridEventListener<'cellClick'> = (
    params
  ) => {
    if (params.field !== 'redeem_active' && params.field !== 'checkin_active') {
      navigate(`/app/event/category-detail/${params.id}`);
    }
  };
  return (
    <Box sx={{ mt: 2 }}>
      <DataGrid
        hideFooterPagination
        autoHeight
        disableSelectionOnClick
        disableColumnMenu
        loading={listCategory.isLoading}
        getRowId={(record) => get(record, "id")}
        rows={get(listCategory, "data.data", [])}
        rowCount={get(listCategory, "data.data", []).length}
        components={{
          NoRowsOverlay: NoOverlay,
        }}
        onCellClick={handleEvent}
        columns={[
          {
            field: "category",
            headerName: "Category",
            flex: 1,
            minWidth: 250,
            sortable: false,
            filterable: false,
            headerAlign: "center",
          },
          {
            field: "total_all",
            headerName: "Total Ticket",
            flex: 1,
            minWidth: 150,
            sortable: false,
            filterable: false,
            headerAlign: "center",
            align: "center"
          },
          {
            field: "redeem_total",
            headerName: "Total Redeem",
            flex: 1,
            minWidth: 150,
            sortable: false,
            filterable: false,
            headerAlign: "center",
            align: "center"
          },
          {
            field: "checkin_total",
            headerName: "Total Checkin",
            flex: 1,
            minWidth: 150,
            sortable: false,
            filterable: false,
            headerAlign: "center",
            align: "center"
          },
          {
            field: "first_checkin_total",
            headerName: "Total First Checkin",
            flex: 1,
            minWidth: 150,
            sortable: false,
            filterable: false,
            headerAlign: "center",
            align: "center"
          },
          {
            field: "redeem_active",
            headerName: "Redeem Active",
            flex: 1,
            minWidth: 150,
            sortable: false,
            filterable: false,
            headerAlign: "center",
            renderCell: (params) => {
              const handleChange = (e:any) => {
                e.stopPropagation();
                category.changeRedeemStatus(params.row.id).then(() => listCategory.refetch());
              };

              return <Switch
                checked={params.value}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />;
            }
          },
          {
            field: "checkin_active",
            headerName: "Checkin Active",
            flex: 1,
            minWidth: 150,
            sortable: false,
            filterable: false,
            headerAlign: "center",
            renderCell: (params) => {
              const handleChange = (e:any) => {
                e.stopPropagation();
                category.changeCheckinStatus(params.row.id).then(() => listCategory.refetch());
              };

              return <Switch
                checked={params.value}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />;
            }
          }
        ]}
        sx={{
          ".MuiDataGrid-columnHeaderTitle": {
            fontWeight: 600,
          },
        }}
      />
    </Box>
  );
}
