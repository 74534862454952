import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Stack,
  Switch,
  TextField,
  useMediaQuery,
  useTheme
} from "@mui/material";
import CookieJs from "js-cookie";
import { get } from "lodash";
import Moment from "moment";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Breadcrumb from "../components/Breadcrumb";
import DateRangePicker from "../components/DateRangePicker";
import MainLayout from "../components/MainLayout";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";
import useNotification from "../hooks/useNotification";
import event from "../services/event";
import queryDefault from "../utils/queryDefault";

export default function EventEditPage() {
  const { id } = useParams();
  const notification = useNotification();
  const navigate = useNavigate();
  const user = JSON.parse(CookieJs.get("USER") || "");
  const schema = yup.object().shape({
    name: yup.string().required("event name is a required field"),
    date_from: yup.string().required("date is a required field"),
  });
  const currentEvent = useQuery("view-event", () => event.viewEventDetail(id));
  const form = useForm({
    defaultValues: {
      name: "",
      date_from: "",
      date_to: "",
      username: user.username,
      is_custom_wristband: false,
      is_link: false
    },
    resolver: yupResolver(schema),
  });

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const createEvent = useMutation("create_event", event.createEvent, {
    onSuccess: () => {
      navigate("/app/event/");

      notification.onOpen({
        message: "Event was successfully created!",
        type: "success",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const updateEvent = useMutation("update-event", event.updateEvent, {
    onSuccess: () => {
      navigate("/app/event/");

      notification.onOpen({
        message: "Event was successfully created!",
        type: "success",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const onSubmit = (values: any) => {
    if (id) {
      updateEvent.mutate({
        data: {
          ...values,
          eventId: id,
        },
      });
    } else {
      createEvent.mutate({
        data: values,
      });
    }
  };

  useEffect(() => {
    if (id) {
      console.log(currentEvent)
      form.setValue("name", get(currentEvent, "data.data.name"));
      form.setValue("date_from", get(currentEvent, "data.data.date_from"));
      form.setValue("date_to", get(currentEvent, "data.data.date_to"));
      form.setValue(
        "is_custom_wristband",
        get(currentEvent, "data.data.is_custom_wristband")
      );
      form.setValue(
        "is_link",
        get(currentEvent, "data.data.is_link")
      );
    }
  }, [currentEvent, form, id]);

  return (
    <MainLayout>
      <Breadcrumb
        label={id ? "Edit Event" : "Create Event"}
        breadcrumbs={[
          { label: "Event", href: "/app/event" },
          {
            label: id ? "Edit Event" : "Create Event",
            href: "/app/events/create-event",
          },
        ]}
      />

      <ProtectedPermissionPage acceptPermissions={[99]}>
        {!currentEvent.isLoading ? (
          <Box sx={{ mt: 2 }}>
            <Grid container>
              <Grid item lg={4} xs={12}>
                <FormControl fullWidth>
                  <FormLabel required>Event Name</FormLabel>
                  <Controller
                    name="name"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ mt: 2 }}>
                  <FormLabel required>Event Date</FormLabel>
                  <Controller
                    name="date_from"
                    control={form.control}
                    render={({ field: { ref, ...field } }) => (
                      <DateRangePicker
                        date_from={
                          id
                            ? new Date(get(currentEvent, "data.data.date_from"))
                            : null
                        }
                        date_to={
                          id
                            ? new Date(get(currentEvent, "data.data.date_to"))
                            : null
                        }
                        fullWidth={isDesktop ? false : true}
                        onApply={(date: any) => {
                          form.setValue(
                            "date_from",
                            Moment(date[0]).format("YYYY-MM-DD")
                          );
                          form.setValue(
                            "date_to",
                            Moment(date[1]).format("YYYY-MM-DD")
                          );
                        }}
                        onClear={() => {
                          form.setValue("date_from", "");
                          form.setValue("date_to", "");
                        }}
                      />
                    )}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <Controller
                    name="is_custom_wristband"
                    control={form.control}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        control={<Switch disabled={!!id} checked={value} onChange={onChange} />}
                        label="Custom Wristband"
                      />
                    )}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <Controller
                    name="is_link"
                    control={form.control}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        control={<Switch checked={value} onChange={onChange} />}
                        label="Linked Wristband"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <></>
        )}
        <Box sx={{ mt: 3 }}>
          <Stack direction="row" spacing={1}>
            <LoadingButton
              variant="contained"
              onClick={form.handleSubmit(onSubmit)}
              loading={createEvent.isLoading}
            >
              SAVE
            </LoadingButton>

            <Button variant="outlined" onClick={() => navigate(-1)}>
              CANCEL
            </Button>
          </Stack>
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
