import { CssBaseline, ThemeProvider } from "@mui/material";
import { useEffect } from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import {
  QueryCache,
  MutationCache,
  QueryClient,
  QueryClientProvider,
} from "react-query";
import { Provider } from "react-redux";
import { useLocation } from "react-router-dom";
import { AlertProvider } from "./context/Alert";
import Routes from "./routes";
import CookieJs from "js-cookie";

import { persistor, store } from "./store";
import themes from "./themes";
import { PersistGate } from "redux-persist/integration/react";
import useNotification from "./hooks/useNotification";

function App() {
  const location = useLocation();
  const notification = useNotification();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location.pathname]);

  const client = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchInterval: false,
      },
    },
    queryCache: new QueryCache({
      onError: (error) => {
        console.log(error, "dada");
      },
    }),
    mutationCache: new MutationCache({
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          notification.onOpen({
            message: "Network error, please try again later",
            type: "error",
            position: "top",
          });

          client.cancelMutations();
        }

        if (error?.response?.status === 401) {
          notification.onOpen({
            message: "Your session has been expired",
            type: "error",
            position: "top",
          });

          CookieJs.remove("ID_USER_ACCOUNT");
          CookieJs.remove("ID_USER");
          CookieJs.remove("USER");

          window.location.href = "/";
        }
      },
    }),
  });

  return (
    <HelmetProvider>
      <Helmet titleTemplate="%s - Ticket Management"></Helmet>
      <QueryClientProvider client={client}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <ThemeProvider theme={themes()}>
              <AlertProvider>
                <CssBaseline />
                <Routes />
              </AlertProvider>
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </HelmetProvider>
  );
}

export default App;
